import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  proposalList: { loading: false, data: null, error: null },
  createProposal: { loading: false, data: null, error: null },
  updateProposal: { loading: false, data: null, error: null },
  deleteProposal: { loading: false, data: null, error: null },
  getProposalById: { loading: false, data: null, error: null },
  archiveSmartProposal: { loading: false, data: null, error: null },
  bookMarkSmartProposal: { loading: false, data: null, error: null },
  proposalActivityList: { loading: false, data: null, error: null },
  sendProposal: { loading: false, data: null, error: null },
  proposalKpi: { loading: false, data: null, error: null },
  unArchiveSmartProposal: { loading: false, data: null, error: null },
  unBookMarkSmartProposal: { loading: false, data: null, error: null },
  archiveList: {loading: false, data: null, error: null}
};

export const proposalSlice = createSlice({
  name: "proposal",
  initialState,
  reducers: {
    fetchProposalListRequest: (proposal, action) => {
      proposal.proposalList.loading = true;
      proposal.proposalList.error = null;
    },
    fetchProposalListSuccess: (proposal, action) => {
      proposal.proposalList.data = action.payload;
      proposal.proposalList.loading = false;
      proposal.proposalList.error = null;
    },
    fetchProposalListFailure: (proposal, action) => {
      proposal.proposalList.data = null;
      proposal.proposalList.loading = false;
      proposal.proposalList.error = action.payload;
    },

    // create proposal
    createProposalRequest: (proposal, action) => {
      proposal.createProposal.loading = true;
      proposal.createProposal.error = null;
    },
    createProposalSuccess: (proposal, action) => {
      proposal.createProposal.data = action.payload;
      proposal.createProposal.loading = false;
      proposal.createProposal.error = null;
      // proposal.proposalList = addNewProposalSuccess(action.payload, {
      //   ...proposal.proposalList,
      // });
    },
    createProposalFailure: (proposal, action) => {
      proposal.createProposal.data = null;
      proposal.createProposal.loading = false;
      proposal.createProposal.error = action.payload;
    },

    // update proposal details
    updateProposalRequest: (proposal, action) => {
      proposal.updateProposal.loading = true;
      proposal.updateProposal.error = null;
    },
    updateProposalSuccess: (proposal, action) => {
      proposal.updateProposal.data = action.payload;
      proposal.updateProposal.loading = false;
      proposal.updateProposal.error = null;
      proposal.proposalList = updateProposalData(
        action.payload,
        proposal.proposalList
      );
    },
    updateProposalFailure: (proposal, action) => {
      proposal.updateProposal.data = null;
      proposal.updateProposal.loading = false;
      proposal.updateProposal.error = action.payload;
    },

    // delete proposal
    deleteProposalRequest: (proposal, action) => {
      proposal.deleteProposal.loading = true;
      proposal.deleteProposal.error = null;
    },
    deleteProposalSuccess: (proposal, action) => {
      proposal.deleteProposal.loading = false;
      proposal.deleteProposal.error = null;
      proposal.deleteProposal.data = action.payload;
      proposal.proposalList = deleteProposalFunction(action.payload, {
        ...proposal.proposalList,
      });
    },
    deleteProposalFailure: (proposal, action) => {
      proposal.deleteProposal.data = null;
      proposal.deleteProposal.loading = false;
      proposal.deleteProposal.error = action.payload;
    },

    // get by id proposal details
    getProposalDetailsRequest: (proposal, action) => {
      proposal.getProposalById.loading = true;
      proposal.getProposalById.error = null;
    },
    getProposalDetailsSuccess: (proposal, action) => {
      proposal.getProposalById.loading = false;
      proposal.getProposalById.error = null;
      proposal.getProposalById.data = action.payload;
    },
    getProposalDetailsFailure: (proposal, action) => {
      proposal.getProposalById.data = null;
      proposal.getProposalById.loading = false;
      proposal.getProposalById.error = action.payload;
    },

    // archive proposal
    archiveSmartProposalRequest: (state, action) => {
      state.archiveSmartProposal.loading = true;
      state.archiveSmartProposal.error = null;
    },
    archiveSmartProposalSuccess: (state, action) => {
      state.archiveSmartProposal.data = action.payload;
      state.archiveSmartProposal.loading = false;
      state.archiveSmartProposal.error = null;
    },
    archiveSmartProposalFailure: (state, action) => {
      state.archiveSmartProposal.data = null;
      state.archiveSmartProposal.loading = false;
      state.archiveSmartProposal.error = action.payload;
    },

    // bookmark proposal
    bookmarkSmartProposalRequest: (state, action) => {
      state.bookMarkSmartProposal.loading = true;
      state.bookMarkSmartProposal.error = null;
    },
    bookmarkSmartProposalSuccess: (state, action) => {
      state.bookMarkSmartProposal.data = action.payload;
      state.bookMarkSmartProposal.loading = false;
      state.bookMarkSmartProposal.error = null;
    },
    bookmarkSmartProposalFailure: (state, action) => {
      state.bookMarkSmartProposal.data = null;
      state.bookMarkSmartProposal.loading = false;
      state.bookMarkSmartProposal.error = action.payload;
    },

    // fetch proposal activity
    fetchProposalActivityListRequest: (state, action) => {
      state.proposalActivityList.loading = true;
      state.proposalActivityList.error = null;
    },
    fetchProposalActivityListSuccess: (state, action) => {
      state.proposalActivityList.data = action.payload;
      state.proposalActivityList.loading = false;
      state.proposalActivityList.error = null;
    },
    fetchProposalActivityListFailure: (state, action) => {
      state.proposalActivityList.data = null;
      state.proposalActivityList.loading = false;
      state.proposalActivityList.error = action.payload;
    },

    sendProposalRequest: (proposal, action) => {
      proposal.sendProposal.loading = true;
      proposal.sendProposal.error = null;
    },
    sendProposalSuccess: (proposal, action) => {
      proposal.sendProposal.data = action.payload;
      proposal.sendProposal.loading = false;
      proposal.sendProposal.error = null;
      proposal.proposalList = updateProposalData(
        action.payload,
        proposal.proposalList
      );
    },
    sendProposalFailure: (proposal, action) => {
      proposal.sendProposal.data = null;
      proposal.sendProposal.loading = false;
      proposal.sendProposal.error = action.payload;
    },

    getProposalKPIRequest: (state, action) => {
      state.proposalKpi.loading = true;
      state.proposalKpi.error = null;
    },
    getProposalKPISuccess: (state, action) => {
      state.proposalKpi.data = action.payload;
      state.proposalKpi.loading = false;
      state.proposalKpi.error = null;
    },
    getProposalKPIFailure: (state, action) => {
      state.proposalKpi.data = null;
      state.proposalKpi.loading = false;
      state.proposalKpi.error = action.payload;
    },

    // un-archive proposal
    unArchiveSmartProposalRequest: (state, action) => {
      state.unArchiveSmartProposal.loading = true;
      state.unArchiveSmartProposal.error = null;
    },
    unArchiveSmartProposalSuccess: (state, action) => {
      state.unArchiveSmartProposal.data = action.payload;
      state.unArchiveSmartProposal.loading = false;
      state.unArchiveSmartProposal.error = null;
    },
    unArchiveSmartProposalFailure: (state, action) => {
      state.unArchiveSmartProposal.data = null;
      state.unArchiveSmartProposal.loading = false;
      state.unArchiveSmartProposal.error = action.payload;
    },

    // un-bookmark proposal
    unBookmarkSmartProposalRequest: (state, action) => {
      state.unBookMarkSmartProposal.loading = true;
      state.unBookMarkSmartProposal.error = null;
    },
    unBookmarkSmartProposalSuccess: (state, action) => {
      state.unBookMarkSmartProposal.data = action.payload;
      state.unBookMarkSmartProposal.loading = false;
      state.unBookMarkSmartProposal.error = null;
    },
    unBookmarkSmartProposalFailure: (state, action) => {
      state.unBookMarkSmartProposal.data = null;
      state.unBookMarkSmartProposal.loading = false;
      state.unBookMarkSmartProposal.error = action.payload;
    },

    fetchArchiveListRequest: (archive, action) => {
      archive.archiveList.loading = true;
      archive.archiveList.error = null;
    },
    fetchArchiveListSuccess: (archive, action) => {
      archive.archiveList.data = action.payload;
      archive.archiveList.loading = false;
      archive.archiveList.error = null;
    },
    fetchArchiveListFailure: (archive, action) => {
      archive.archiveList.data = null;
      archive.archiveList.loading = false;
      archive.archiveList.error = action.payload;
    },

    //Reset proposal state
    resetProposal: (proposal, action) => {
      proposal.createProposal.data = null;
      proposal.createProposal.loading = false;
      proposal.createProposal.error = null;

      proposal.updateProposal.data = null;
      proposal.updateProposal.loading = false;
      proposal.updateProposal.error = null;

      proposal.deleteProposal.data = null;
      proposal.deleteProposal.loading = false;
      proposal.deleteProposal.error = null;

      proposal.archiveSmartProposal.data = null;
      proposal.archiveSmartProposal.loading = false;
      proposal.archiveSmartProposal.error = null;

      proposal.bookMarkSmartProposal.data = null;
      proposal.bookMarkSmartProposal.loading = false;
      proposal.bookMarkSmartProposal.error = null;

      proposal.sendProposal.data = null;
      proposal.sendProposal.loading = false;
      proposal.sendProposal.error = null;

      proposal.unArchiveSmartProposal.data = null;
      proposal.unArchiveSmartProposal.loading = false;
      proposal.unArchiveSmartProposal.error = null;

      proposal.unBookMarkSmartProposal.data = null;
      proposal.unBookMarkSmartProposal.loading = false;
      proposal.unBookMarkSmartProposal.error = null;
    },
  },
});

// addNew proposal function
function addNewProposalSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

// update proposal function
function updateProposalData(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// delete proposal function
function deleteProposalFunction(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchProposalListRequest,
  fetchProposalListSuccess,
  fetchProposalListFailure,

  createProposalRequest,
  createProposalSuccess,
  createProposalFailure,

  updateProposalRequest,
  updateProposalSuccess,
  updateProposalFailure,

  deleteProposalRequest,
  deleteProposalSuccess,
  deleteProposalFailure,

  getProposalDetailsRequest,
  getProposalDetailsSuccess,
  getProposalDetailsFailure,

  archiveSmartProposalRequest,
  archiveSmartProposalSuccess,
  archiveSmartProposalFailure,

  bookmarkSmartProposalRequest,
  bookmarkSmartProposalSuccess,
  bookmarkSmartProposalFailure,

  fetchProposalActivityListRequest,
  fetchProposalActivityListSuccess,
  fetchProposalActivityListFailure,

  sendProposalRequest,
  sendProposalSuccess,
  sendProposalFailure,

  getProposalKPIRequest,
  getProposalKPISuccess,
  getProposalKPIFailure,

  unArchiveSmartProposalRequest,
  unArchiveSmartProposalSuccess,
  unArchiveSmartProposalFailure,

  unBookmarkSmartProposalRequest,
  unBookmarkSmartProposalSuccess,
  unBookmarkSmartProposalFailure,
  
  fetchArchiveListRequest,
  fetchArchiveListSuccess,
  fetchArchiveListFailure,

  resetProposal,
} = proposalSlice.actions;

export const getProposalListState = (store) => store?.proposal?.proposalList;
export const getProposalArchiveListState = (store) =>
  store?.proposal?.archiveSmartProposal;
export const getProposalBookmarkListState = (store) =>
  store?.proposal?.bookMarkSmartProposal;
export const getProposalCreateState = (store) =>
  store?.proposal?.createProposal;
export const getProposalUpdateState = (store) =>
  store?.proposal?.updateProposal;
export const getProposalDeleteState = (store) =>
  store?.proposal?.deleteProposal;
export const getProposalGetByIdState = (store) =>
  store?.proposal?.getProposalById;
export const getProposalLatestActivityListState = (store) =>
  store?.proposal?.proposalActivityList;
export const getSendProposalState = (store) => store?.proposal?.sendProposal;
export const getProposalKPIState = (state) => state.proposal?.proposalKpi;
export const getProposalUnArchiveListState = (store) =>
  store?.proposal?.unArchiveSmartProposal;
export const getProposalUnBookmarkListState = (store) =>
  store?.proposal?.unBookMarkSmartProposal;
export const getArchiveListState = (store) => store?.proposal?.archiveList;

export default proposalSlice.reducer;
